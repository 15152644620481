import Image, { ImageProps, StaticImageData } from 'next/image';
import CloudFrontImage from '@components/Image/CloudFrontImage';
// import CloudinaryImage from '@components/Image/CloudinaryImage'
// eslint-disable-next-line lodash/import-scope
import { isString } from 'lodash';
export interface WhichImageProps
  extends Omit<ImageProps, 'src' | 'alt' | 'loader'> {
  path: string | StaticImageData;
  title: string;
  width?: number;
  height?: number;
  fill?: boolean | undefined;
  priority?: boolean | undefined;
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<any> | undefined;
}

export enum WhichImageType {
  CLOUDFRONTIMAGE = 'CloudFrontImage',
  CLOUDINARYIMAGE = 'CloudinaryImage',
  NEXTIMAGE = 'NextImage',
}

// This a hack to bypass the use of next/image where possible to reduce the use of Magnolias CDN resources.
// We will attempt to load scaled images from either cloudfront or cloudinary where possible.

const WhichImage: React.FC<WhichImageProps> = ({
  path = '',
  title,
  width,
  height,
  fill,
  priority,
  style,
  onClick,
  ...rest
}) => {
  if (!path) {
    return <></>;
  }

  //  static image data
  if (!isString(path)) {
    return (
      <Image
        data-type={WhichImageType.NEXTIMAGE}
        src={path}
        alt={title}
        width={width}
        height={height}
        style={style ? style : undefined}
        onClick={onClick ? onClick : () => {}}
        {...rest}
      />
    );
  }

  const cloudfront = path.includes('cloudfront.net');

  // Disabled until we pay our bill
  // const cloudinary = path.includes('cloudinary.com');

  if (cloudfront) {
    return (
      <CloudFrontImage
        data-type={WhichImageType.CLOUDFRONTIMAGE}
        loading="lazy"
        src={path}
        alt={title}
        width={width}
        height={height}
        style={style ? style : undefined}
        onClick={onClick ? onClick : () => {}}
        {...rest}
      />
    );
  }

  // Disabled until we pay our bill
  // if(cloudinary) {
  //   return <CloudinaryImage
  //             data-type={WhichImageType.CLOUDINARYIMAGE}
  //             loading='lazy'
  //             src={path}
  //             alt={title}
  //             width={width}
  //             height={height}
  //             style={ style ? style : undefined}
  //             onClick={onClick ? onClick : () => {}}
  //             {...rest}
  //          />
  // }

  return fill ? (
    <Image
      data-type={WhichImageType.NEXTIMAGE}
      src={path}
      alt={title}
      fill={fill ? fill : undefined}
      priority={priority ? priority : false}
      style={style ? style : undefined}
      onClick={onClick ? onClick : () => {}}
      {...rest}
    />
  ) : (
    <Image
      data-type={WhichImageType.NEXTIMAGE}
      src={path}
      alt={title}
      priority={priority ? priority : false}
      width={width}
      height={height}
      style={style ? style : undefined}
      onClick={onClick ? onClick : () => {}}
      {...rest}
    />
  );
};

export default WhichImage;
