interface ImageProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<any> | undefined;
}

const CloudFrontImage = ({
  src,
  alt,
  width = 640,
  height = 480,
  style,
  onClick,
  ...rest
}: ImageProps): React.ReactElement => {
  if (!src) {
    return <></>;
  }

  const lastIndex = src.lastIndexOf('/');
  const imagePath = src.slice(0, lastIndex);
  const filename = src.slice(lastIndex);

  let newSrc = src + '/' + height + 'x' + width;

  if (filename.includes('.')) {
    newSrc =
      imagePath + '/' + height + 'x' + width + '.' + src.split('.').pop() ?? '';
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <img
      src={newSrc}
      alt={alt}
      width={width}
      height={height}
      style={style ? style : undefined}
      onClick={onClick ? onClick : () => {}}
      {...rest}
    />
  );
};

export default CloudFrontImage;
